.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  /* Full width of the viewport */
  background-color: #f5f5f5;
  padding: 0px;
}

.card {
  background-color: #fff;
  padding: 12px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 92vw;
  /* Ensure the card doesn't overflow the viewport */
  max-height: 100vh;
  /* Adjust height to 90% of the viewport height */
  width: 100%;
  text-align: center;
  overflow-y: auto;
  /* Scroll content if needed */
}

.title {
  font-size: 32px;
  margin-bottom: 8px;
  font-weight: 400;
  color: #000;
}

.notice {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.3;
  color: #000;
}

.last-update {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.3;
  color: #333;
  margin-bottom: 2px;
  text-align: right;
}

.termsContainer {
  position: relative;
  max-height: 56vh;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 16px 8px 0px 24px;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  text-align: left;
}

.acceptButton {
  padding: 10px 20px 12px 20px;
  font-size: 1rem;
  font-weight: 500;
  background-color: #17254C;
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
}